<template>
  <div>
    <v-autocomplete
      hide-details
      :value="value"
      clearable
      :items="equipmentList"
      dense
      flat
      filled
      placeholder="Select Equipment"
      item-color="cyan"
      color="cyan"
      solo
      item-text="name"
      item-value="id"
      :loading="isLoading"
      :menu-props="{
        contentClass: isLoading ? 'autocomplete-dropdown-menu is-loading' : '',
      }"
      v-bind="$attrs"
      @change="selectEquipment"
      @click:clear="$emit('click:clear')"
      @keyup.enter="doSearch"
      :search-input.sync="searchInput"
      :filter="filterEquipments"
    >
      <template v-slot:no-data>
        <v-list-item>
          <div
            v-if="isLoading"
            class="w-100 d-flex align-center justify-center py-2"
          >
            <v-progress-circular indeterminate color="cyan" />
          </div>
          <v-list-item-title v-else v-html="'No Equipment(s) Found.'" />
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-avatar left>
          <v-img v-if="item.image" :src="item.image"></v-img>
          <v-img v-else :src="$defaultImage"></v-img>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16 max-content-width"
            >{{ item.name }}</v-list-item-title
          >
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16 max-content-width"
            >{{ item.serial_no }}
            <v-chip
              color="blue white--text"
              label
              class="ml-2"
              v-if="item.type && item.type == 'company'"
              style="height: 21px; display: inline-block"
            >
              <span class="font-size-14 font-weight-500"> Gaia </span>
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";

export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  name: "equipment-dropdown",
  data() {
    return {
      isLoading: false,
      equipmentList: [],
      timeout: null,
      searchInput: "",
      timeoutLimit: 1000,
    };
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    customerId: {
      type: [String, Number, Object],
      default: null,
    },
    propertyId: {
      type: [String, Number, Object],
      default: null,
    },
  },
  watch: {
    customerId() {
      this.isLoading = true;
      this.equipmentList = [];
      this.getEquipments();
    },
    propertyId() {
      this.isLoading = true;
      this.equipmentList = [];
      this.getEquipments();
    },
    searchInput() {
      this.doTypingSearch();
    },
  },
  methods: {
    doTypingSearch() {
      this.isLoading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.doSearch();
      }, this.timeoutLimit);
    },
    filterEquipments(item, queryText) {
      const itemText = `${item?.name ?? ""} ${item?.serial_number ?? ""} ${
        item?.model_no ?? ""
      } ${item?.model ?? ""} ${item?.brand ?? ""} ${item?.barcode ?? ""} ${
        item?.description ?? ""
      }`;
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    doSearch() {
      const param = this.searchInput || "";
      if (param.length < 3) {
        this.isLoading = false;
        return;
      }
      const selectedEquipment = this.equipmentList.find(
        (row) => row.id === this.value
      );
      if (selectedEquipment?.id && selectedEquipment.name === param) {
        this.isLoading = false;
        return;
      }
      this.getEquipments(param);
    },
    selectEquipment(value) {
      this.searchInput = "";
      this.$nextTick(() => {
        this.$emit("input", value);
        this.$emit("change", value);
        const valObj = this.equipmentList.find((row) => row.id === value);
        this.$emit("change:object", valObj);
      });
    },
    getEquipments(search = "") {
      if (
        this.equipmentList.length &&
        !search &&
        this.customerId &&
        this.propertyId
      ) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "equipment-list",
          data: {
            customer: this.customerId,
            property: this.propertyId,
            search,
            value: this.value,
          },
        })
        .then(({ data }) => {
          this.equipmentList = data;
        })
        .catch((error) => {
          this.$emit("error", error.message || "Failed to load equipment");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (!this.value || !this.equipmentList.length) {
      this.getEquipments();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>
